import { useState } from "react";

export const usePaginator = () => {
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortDirection, setDirection] = useState<ASortDirection>('Asc');
    const [sortColumn, setSortColumn] = useState('');

    return {
        query,
        setQuery,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        sortDirection,
        setDirection,
        sortColumn,
        setSortColumn
    }
}