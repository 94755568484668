import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { Avatar, Box, IconButton, Stack, SvgIcon, Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React from "react";
import { usePopover } from "../../hooks/use-popover";
import { AccountPopover } from "./account-popover";
import LanguagePopover from "./language-popover";
import { useSelector } from "react-redux";
import { getInitials } from "src/utils/get-initials";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav: React.FC<{ onNavOpen: () => void; isOpen: boolean }> = ({
  onNavOpen,
  isOpen,
}) => {
  const accountPopover = usePopover();
  const account = useSelector((state: AppStore) => state.account);
  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            lg: isOpen ? `${SIDE_NAV_WIDTH}px` : "unset",
          },
          top: 0,
          width: {
            lg: isOpen ? `calc(100% - ${SIDE_NAV_WIDTH}px)` : "unset",
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <IconButton onClick={onNavOpen}>
              <SvgIcon fontSize="small">
                <Bars3Icon />
              </SvgIcon>
            </IconButton>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            {/* <Tooltip title="Contacts">
              <IconButton>
                <SvgIcon fontSize="small">
                  <UsersIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications">
              <IconButton>
                <Badge
                  badgeContent={4}
                  color="success"
                  variant="dot"
                >
                  <SvgIcon fontSize="small">
                    <BellIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip> */}
            <LanguagePopover />
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
            >
              {getInitials(account.name)}
            </Avatar>
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};
