import { Box, Divider, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsAuthenticated } from "src/store/account-slice";

export const AccountPopover: React.FC<{
  anchorEl: any;
  onClose: () => void;
  open: boolean;
}> = (props) => {
  const { anchorEl, onClose, open } = props;
  const router = useNavigate();
  const dispatch = useDispatch();

  const account = useSelector((state: AppStore) => state.account);

  const handleSignOut = useCallback(() => {
    onClose?.();
    dispatch(setIsAuthenticated(false));
    router("/auth/login");
  }, [onClose, router]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: 200 } } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Konto</Typography>
        <Typography color="text.secondary" variant="body2">
          {account.name}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Abmelden</MenuItem>
      </MenuList>
    </Popover>
  );
};
