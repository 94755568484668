import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  SortDirection,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Title } from "src/components/title";
import { useSelection } from "src/hooks/use-selection";
import { useLazyAxios } from "src/hooks/useAxios";
import { SearchBox } from "src/components/search-box";
import { CustomersTable } from "src/pages/customers/customers-table";
import { usePaginator } from "src/hooks/use-paginator";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
// import dayjs from 'dayjs';

export const CustomersPage = () => {
  const {
    page,
    query,
    rowsPerPage,
    setDirection,
    setPage,
    setQuery,
    setRowsPerPage,
    setSortColumn,
    sortColumn,
    sortDirection,
  } = usePaginator();
  const dt = dayjs().date(1).month(0).year(2023).minute(0).second(0).millisecond(0).toDate();
  const [from, setFrom] = useState<Date>(dt);
  const [to, setTo] = useState<Date>(new Date());

  const { isLoading, response, execute } = useLazyAxios("api/User/GetAll");
  const { execute: download, isLoading: Downloading } = useLazyAxios(
    "api/User/ExportToExcel",
    "blob"
  );
  const loadData = async () => {
    await execute(undefined, {
      query: query,
      sortColumn: sortColumn as any,
      pageSize: rowsPerPage,
      pageIndex: page,
      sortDirection: sortDirection,
      // @ts-ignore
      startDate: from,
      endDate: to,
    });
  };
  const totalRecords = response?.count || 0;
  const data = response?.items || [];

  useEffect(() => {
    loadData();
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  return (
    <>
      <Title title="Konto" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Kunden</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    onClick={async (e) => {
                      const resp = await download({
                        query: query,
                        sortColumn: sortColumn as any,
                        pageSize: rowsPerPage,
                        pageIndex: page,
                        sortDirection: sortDirection,
                        // @ts-ignore
                        startDate: from,
                        endDate: to,
                      } as any);
                      const url = window.URL.createObjectURL(new Blob([resp?.response]));
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `Users_${new Date().toLocaleString()}.xlsx`);

                      // Append to html link element page
                      document.body.appendChild(link);

                      // Start download
                      link.click();

                      // Clean up and remove the link
                      link.parentNode?.removeChild(link);
                    }}
                    color="inherit"
                    disabled={Downloading}
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportieren
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Card sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item sm={12} md={4}>
                  <SearchBox
                    label="Suche (Name, E-Mail, Telefon, Empfehlungscode)"
                    onSubmit={() => {
                      setPage(0);
                      loadData();
                    }}
                    setText={setQuery}
                    text={query}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <MobileDatePicker
                    orientation="landscape"
                    label="Datum von"
                    value={from}
                    sx={{ width: "100%" }}
                    onChange={(e) => setFrom(e!)}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <MobileDatePicker
                    orientation="landscape"
                    label="Datum bis"
                    value={to}
                    sx={{ width: "100%" }}
                    onChange={(e) => setTo(e!)}
                  />
                </Grid>
                <Grid item sm={12} md={12} textAlign={"end"}>
                  <Button onClick={loadData} variant="contained">
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <CustomersTable
              count={totalRecords}
              items={data}
              onPageChange={(e, v) => {
                setPage(v);
              }}
              onRowsPerPageChange={(e) => {
                setPage(0);
                setRowsPerPage(e.target.value);
              }}
              page={page}
              rowsPerPage={rowsPerPage}
              setDirection={setDirection}
              setSortColumn={setSortColumn}
              sortDirection={sortDirection}
              sortColumn={sortColumn}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};
