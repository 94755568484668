import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
import EuroIcon from "@heroicons/react/24/solid/CurrencyEuroIcon";
import UserPlusIcon from "@heroicons/react/24/solid/UserPlusIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import { SvgIcon } from "@mui/material";

export const items: {
  title: string;
  path: string;
  icon: JSX.Element;
  disabled?: boolean;
  external?: boolean;
}[] = [
  {
    title: "Kunden",
    path: "/customers",
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Empfehlungen",
    path: "/referrals",
    icon: (
      <SvgIcon fontSize="small">
        <EuroIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Einstellungen",
    path: "/settings",
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    ),
  },
];
