import { createSlice } from "@reduxjs/toolkit";

const initialState: ReduxAccountOptions = {
  isAuthenticated: localStorage.getItem("authenticated") == "true",
  name: "",
  type: "",
  profile: {
    email: "",
    type: "Free",
  },
};

export const AccountSlice = createSlice({
  name: "Konto",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: IAction<boolean>) => {
      state.isAuthenticated = action.payload;
      if (!action.payload) {
        localStorage.clear();
      }
    },
    setProfile: (state, action: IAction<MyProfile>) => {
      state.profile = action.payload;
      if (action.payload) {
        state.name = action.payload.name || "";
        state.type = action.payload.type || "";
      }
    },
    setProfileMini: (state, action: IAction<boolean>) => {
      state.isAuthenticated = action.payload;
      if (!action.payload) {
        localStorage.clear();
      }
    },
  },
});

export const { setIsAuthenticated, setProfile, setProfileMini } = AccountSlice.actions;

export default AccountSlice.reducer;
