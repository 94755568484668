import axios, { AxiosError, ResponseType } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import npProgress from "nprogress";
import { BodyTypes, ParamTypes, ReturnTypes, URLs, getDefaultParams, methods } from "./types";
import toast from "react-hot-toast";

const baseUrl = process.env["REACT_APP_BASE_URL"];

export const useLazyAxios = <
  URL extends URLs = URLs,
  PT = ParamTypes[URL],
  BT = BodyTypes[URL],
  RT = ReturnTypes[URL]
>(
  url: URL,
  rt?: ResponseType | undefined
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState<RT>();

  const execute = async (params?: PT, body?: BT) => {
    const loaderId = toast.loading("Bitte warten...", { id: "loader" });
    npProgress.start();
    setIsLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("token") || "{}") as TokenResponse;
      let lang = navigator.language;
      lang = lang.includes("en") ? "en-US" : "de-DE";
      const response = await axios<RT>({
        baseURL: baseUrl,
        url: url,
        method: methods[url],
        params: params || getDefaultParams(url),
        responseType: rt,
        data: body,
        headers: {
          Authorization: "Bearer " + token.accessToken,
          Platform: "Web",
          "Accept-Language": lang,
        },
      });
      setResponse(response.data);
      setIsLoading(false);
      npProgress.done();
      toast.remove(loaderId);
      const raw = response;
      return { raw, response: response.data };
    } catch (error: unknown) {
      toast.remove(loaderId);
      setIsLoading(false);
      setIsError(true);
      npProgress.done();
      if (error instanceof AxiosError && error.response) {
        if (error.response?.status == 401) {
          if (!pathname.includes("reset-password")) navigate("/auth/login");
        }

        return { raw: error.response, response: error.response.data };
      }
    }
  };

  return { response, isLoading, isError, execute };
};
