import { AllReferrals, Referals, UserList } from "src/pages/customers/types";

export type URLs =
  | "login"
  | "register"
  | "resetPassword"
  | "forgotPassword"
  | "api/Account/MyProfile"
  | "api/Account/UpdateProfile"
  | "manage/info"
  | "api/Referals/AllReferrals"
  | "api/Referals/Referals"
  | "api/Referals/SetIsPaid"
  | "api/Referals/MyReferals"
  | "api/Referals/MyReferalCode"
  | "api/User/GetAll"
  | "api/User/SetActiveInactive"
  | "api/User/ExportToExcel"
  | "api/User/SetSubscriptionStatus";
export type Methods = { [x in URLs]: "POST" | "GET" | "PUT" | "DELETE" | "PATCH" };
export const methods: Methods = {
  login: "POST",
  register: "POST",
  resetPassword: "POST",
  forgotPassword: "POST",
  "api/Account/MyProfile": "GET",
  "api/Account/UpdateProfile": "POST",
  "manage/info": "POST",
  "api/User/GetAll": "POST",
  "api/User/SetSubscriptionStatus": "POST",
  "api/User/ExportToExcel": "GET",
  "api/User/SetActiveInactive": "PATCH",

  "api/Referals/AllReferrals": "POST",
  "api/Referals/SetIsPaid": "POST",
  "api/Referals/Referals": "GET",
  "api/Referals/MyReferals": "GET",
  "api/Referals/MyReferalCode": "GET",
};
export type ParamTypes = {
  login: undefined;
  register: { token1: string };
  resetPassword: undefined;
  forgotPassword: { token2: string };
  "api/User/GetAll": undefined;
  "api/Account/MyProfile": undefined;
  "api/Account/UpdateProfile": undefined;
  "manage/info": undefined;
  "api/User/SetSubscriptionStatus": { id: string; status: "Premium" | "Free" | string };
  "api/User/SetActiveInactive": undefined;

  "api/Referals/AllReferrals": undefined;
  "api/Referals/Referals": { userId: string };
  "api/Referals/SetIsPaid": { referalId: string; isPaid: boolean };
  "api/Referals/MyReferals": undefined;
  "api/Referals/MyReferalCode": undefined;
  "api/User/ExportToExcel": undefined;
};
export type BodyTypes = {
  login: {
    username: string;
    password: string;
  };
  register: { token1: string };
  resetPassword: {
    email: string;
    resetCode: string;
    newPassword: string;
  };
  forgotPassword: { token2: string };
  "api/User/GetAll": PaginationModel<"givenName" | "familyName" | "email" | "emailConfirmed">;
  "api/Account/MyProfile": undefined;
  "api/Account/UpdateProfile": MyProfile;
  "manage/info": {
    newUsername?: string;
    newEmail?: string;
    newPassword?: string;
    oldPassword?: string;
  };
  "api/User/SetSubscriptionStatus": undefined;
  "api/User/SetActiveInactive": { id: string; status: boolean };

  "api/Referals/AllReferrals": PaginationModel<"createdDate" | "byName" | "toName" | "refCode">;
  "api/Referals/Referals": undefined;
  "api/Referals/SetIsPaid": undefined;
  "api/Referals/MyReferals": undefined;
  "api/Referals/MyReferalCode": undefined;
  "api/User/ExportToExcel": undefined;
};
export type ReturnTypes = {
  login: TokenResponse | { success: false };
  register: { token1: string };
  resetPassword: {};
  forgotPassword: { token2: string };
  "api/User/GetAll": ListResponse<UserList>;
  "api/Account/MyProfile": ApiResponse<MyProfile>;
  "api/Account/UpdateProfile": ApiResponse;
  "manage/info": ApiResponse;
  "api/User/SetSubscriptionStatus": ApiResponse;
  "api/User/SetActiveInactive": ApiResponse;
  "api/User/ExportToExcel": ApiResponse;

  "api/Referals/AllReferrals": ListResponse<AllReferrals>;
  "api/Referals/Referals": ApiResponse<Referals[]>;
  "api/Referals/SetIsPaid": ApiResponse;
  "api/Referals/MyReferals": ApiResponse<Referals[]>;
  "api/Referals/MyReferalCode": ApiResponse<{ referenceCode: number }>;
};

export const getDefaultParams = (key: URLs) => {
  switch (key) {
    case "login":
      return { cookieMode: false, persistCookies: false };

    default:
      return undefined;
  }
};

export interface PaginationModel<SortColumns extends any> {
  query?: string;
  pageSize?: number;
  pageIndex?: number;
  sortColumn?: SortColumns;
  sortDirection: "Asc" | "Desc";
}
