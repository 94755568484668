import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { Link as NLink, useNavigate } from "react-router-dom";
import { useLazyAxios } from "src/hooks/useAxios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "src/store/account-slice";

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, isLoading, execute } = useLazyAxios("login");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("E-Mail ist erforderlich"),
      password: Yup.string().max(255).required("Passwort ist erforderlich"),
    }),
    onSubmit: async (values) => {
      const result = await execute(undefined, values);
      const response = result?.response;
      if (response && "accessToken" in response) {
        localStorage.setItem("authenticated", "true");
        localStorage.setItem("token", JSON.stringify(response));
        dispatch(setIsAuthenticated(true));
        navigate("/customers");
      } else {
        toast.error("Ungültiger Benutzername oder Passwort.");
      }
    },
  });

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <div>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4">Anmelden</Typography>
          </Stack>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.username && formik.errors.username)}
                fullWidth
                helperText={formik.touched.username && formik.errors.username}
                label="E-Mail Adresse"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.username}
              />
              <TextField
                error={!!(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Passwort"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
              />
            </Stack>
            {/* {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )} */}
            <Button
              disabled={isLoading}
              fullWidth
              size="large"
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
            >
              Anmelden
            </Button>
          </form>
        </div>
      </Box>
    </Box>
  );
};
