import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IOSSwitch } from "src/components/IOSSwitch";
import { Transition } from "src/components/transition";
import { useLazyAxios } from "src/hooks/useAxios";
import { Scrollbar } from "../../components/scrollbar";
import { AllReferrals } from "../customers/types";
import { Link } from "react-router-dom";

interface ReferralsTableProps {
  count: number;
  items: AllReferrals[];
  onPageChange: (event: any, value: any) => void;
  onRowsPerPageChange: (e) => void;
  page: number;
  rowsPerPage: number;
  setSortColumn: (e: string) => void;
  sortColumn: string;
  sortDirection: ASortDirection;
  setDirection: (e: ASortDirection) => void;
}
export const ReferralsTable: React.FC<ReferralsTableProps> = ({
  count,
  items,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  setDirection,
  setSortColumn,
  sortColumn,
  sortDirection,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState<string>();

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {heads.map((s) => (
                  <TableCell
                    sortDirection={
                      sortColumn === s.id ? (sortDirection == "Asc" ? "asc" : "desc") : false
                    }
                    key={s.id}
                  >
                    {s.sortable ? (
                      <TableSortLabel
                        onClick={(e) => {
                          const isAsc = sortColumn === s.id && sortDirection === "Asc";
                          setDirection(isAsc ? "Desc" : "Asc");
                          setSortColumn(s.id);
                        }}
                        active={s.id == sortColumn}
                        direction={sortDirection == "Asc" ? "asc" : "desc"}
                      >
                        {s.title}
                      </TableSortLabel>
                    ) : (
                      s.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((r, i) => (
                <TableRowTemplate
                  key={r.id}
                  i={i}
                  page={page}
                  r={r}
                  setId={setId}
                  setOpen={setOpen}
                  setTitle={setTitle}
                  rowsPerPage={rowsPerPage}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} bis ${count !== -1 ? count : `mehr als ${to}`}`
        }
        labelRowsPerPage="Zeilen pro Seite:"
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 20, 30, 50, 100, 150]}
      />
      <EarningsDialog open={open} setOpen={setOpen} id={id} title={title} />
    </Card>
  );
};

const heads = [
  {
    id: "id",
    title: "#",
    sortable: false,
  },
  {
    id: "byName",
    title: "Empfohlen von",
    sortable: true,
  },
  {
    id: "toName",
    title: "Registrierter Benutzer",
    sortable: true,
  },
  {
    id: "refCode",
    title: "Empfehlungscode",
    sortable: true,
  },
  {
    id: "isPaid",
    title: "Bezahlt",
    sortable: false,
  },
  {
    id: "subscriptionStatus",
    title: "Abonnement",
    sortable: false,
  },
];

const TableRowTemplate = ({
  r,
  i,
  page,
  rowsPerPage,
  setId,
  setOpen,
  setTitle,
}: {
  r: AllReferrals;
  i: number;
  rowsPerPage: number;
  page: number;
  setTitle: (t: string) => void;
  setOpen: (t: boolean) => void;
  setId: (t: string) => void;
}) => {
  const [row, setRow] = useState(r);
  return (
    <TableRow hover>
      <TableCell>{i + 1 + rowsPerPage * page}</TableCell>
      <TableCell>
        <Box
          component={Button}
          onClick={() => {
            setTitle(row.referredByUserName);
            setId(row.referredByUserId);
            setOpen(true);
          }}
        >
          <Stack direction={"column"}>
            <Typography variant="subtitle2">{row.referredByUserName}</Typography>
            <Typography variant="subtitle2">{row.referredByUserEmail}</Typography>
            <Chip
              size="small"
              label={row.referredByUserEmailConfirmed ? "Verifiziert" : "Unverifiziert"}
              color={row.referredByUserEmailConfirmed ? "success" : "error"}
            />
          </Stack>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          component={Button}
          onClick={() => {
            setTitle(row.registrationUserName);
            setId(row.registrationUserId);
            setOpen(true);
          }}
        >
          <Stack direction={"column"}>
            <Typography variant="subtitle2">{row.registrationUserName}</Typography>
            <Typography variant="subtitle2">{row.registrationUserEmail}</Typography>
            <Chip
              size="small"
              label={row.registrationUserEmailConfirmed ? "Verifiziert" : "Unverifiziert"}
              color={row.registrationUserEmailConfirmed ? "success" : "error"}
            />
          </Stack>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">{row.referralCode}</Typography>
      </TableCell>
      <TableCell>
        <StatusSwitch
          setCheckedChange={(e) => {
            const val = { ...row };
            r.isPaid = e;
            setRow(val);
          }}
          id={row.id}
          isPaid={row.isPaid}
        />
        {row.isPaid && (
          <>
            <Typography>Bezahlt von: {row.paidByName}</Typography>
            <Typography>{row.paidByEmail}</Typography>
            <Typography>Datum: {row.paidOn}</Typography>
          </>
        )}
      </TableCell>
      <TableCell>
        <>
          <Typography variant="subtitle2">{row.subscriptionStatus}</Typography>

          {/* <StatusDropdown id={row.id} default={row.subscriptionStatus} /> */}
          <Typography variant="subtitle2">
            {row.subscriptionDuration === "Year1" ? "1 Jahr" : "6 Monaten"}
          </Typography>
        </>
      </TableCell>
    </TableRow>
  );
};
const StatusSwitch = (props: {
  id: string;
  isPaid: boolean;
  setCheckedChange: (r: boolean) => void;
}) => {
  const [checked, setChecked] = useState(props.isPaid);
  const { execute } = useLazyAxios("api/Referals/SetIsPaid");
  const onChange = async (check: boolean) => {
    setChecked(check);
    await execute(
      {
        referalId: props.id,
        isPaid: checked,
      },
      undefined
    );
    props.setCheckedChange(check);
  };
  return (
    <FormControlLabel
      sx={{ ml: 0 }}
      control={
        <IOSSwitch
          sx={{ ml: 1 }}
          checked={checked}
          onChange={async (e) => await onChange(e.target.checked)}
        />
      }
      label={""}
      labelPlacement="start"
    />
  );
};
const StatusDropdown = (props: { id: string; default: string }) => {
  const [value, setValue] = useState(props.default);

  const { execute } = useLazyAxios("api/User/SetSubscriptionStatus");
  const onChange = async (newVal: string) => {
    setValue(newVal);
    await execute({
      id: props.id,
      status: newVal,
    });
  };
  return (
    <FormControl sx={{ m: 1, ml: 0, minWidth: 80 }} size="small">
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        labelId="status-label"
        value={value}
        label="Status"
        onChange={async (e) => onChange(e.target.value)}
      >
        <MenuItem value={"Free"}>Frei</MenuItem>
        <MenuItem value={"Premium"}>Premium</MenuItem>
      </Select>
    </FormControl>
  );
};
const EarningsDialog = ({
  id,
  open,
  setOpen,
  title,
}: {
  id?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}) => {
  const { isLoading, execute, response } = useLazyAxios("api/Referals/Referals");
  const rows = response?.data || [];
  console.log(rows);
  useEffect(() => {
    if (id)
      (async () => {
        execute({ userId: id });
      })();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog TransitionComponent={Transition} fullScreen open={open} scroll={"paper"}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}&apos;s Ergebnis
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Card>
              <Scrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Empfohlener Benutzer</TableCell>
                        <TableCell>Empfohlenes Datum</TableCell>
                        <TableCell>Punkte/Verdienst</TableCell>
                        <TableCell>Abonnement-Status</TableCell>
                        <TableCell>Bezahlter Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((s) => (
                        <TableRow hover key={s.id}>
                          <TableCell>
                            <Typography variant="subtitle2">
                              Name: {s.registrationUserName}
                            </Typography>
                            <Typography variant="subtitle2">
                              E-Mail: {s.registrationUserEmail}
                            </Typography>
                            <Stack spacing={1} direction="row">
                              <Chip
                                size="small"
                                label={
                                  s.registrationUserEmailConfirmed ? "Verifiziert" : "Unverifiziert"
                                }
                                color={s.registrationUserEmailConfirmed ? "success" : "error"}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell>{s.createdDate}</TableCell>
                          <TableCell>
                            <Chip
                              size="small"
                              label={s.earnedPoints + "€"}
                              color={s.subscriptionStatus == "Free" ? "error" : "success"}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack direction={"row"} spacing={1}>
                              <Typography variant="subtitle2">Status: </Typography>
                              <Chip
                                size="small"
                                label={s.subscriptionStatus}
                                color={s.subscriptionStatus == "Free" ? "error" : "success"}
                              />
                            </Stack>
                            <Typography variant="subtitle2">
                              Dauer: {s.subscriptionDuration}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <>
                              {s.subscriptionStatus != "Free" && (
                                <FormControlLabel
                                  sx={{ ml: 0 }}
                                  control={
                                    <IOSSwitch
                                      sx={{ ml: 1 }}
                                      checked={s.isPaid}
                                      onChange={async (e) => {}}
                                    />
                                  }
                                  label={"Bezahlt: "}
                                  labelPlacement="start"
                                />
                              )}
                              {s.isPaid && (
                                <>
                                  <Typography variant="subtitle2">
                                    Bezahltes Datum: {s.paidOn}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Bezahlt von: {s.paidByName}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Bezahlt per E-Mail: {s.paidByEmail}
                                  </Typography>
                                </>
                              )}
                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                      {rows.length == 0 && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Typography align="center" color="error">
                              Kein Benutzer wird von diesem Benutzer referenziert.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
