import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import "simplebar-react/dist/simplebar.min.css";
import { useNProgress } from "src/hooks/use-nprogress";
import { createTheme } from "src/theme";
import { LoginPage } from "./auth/login";
import { AuthLayout } from "./layouts/auth/AuthLayout";
import { DashboardLayout } from "./layouts/dashboard/layout";
import { Page404 } from "./pages/404";
import { CustomersPage } from "./pages/customers";
import { ProfilePage } from "./pages/profile";
import { SettingsPage } from "./pages/settings";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setIsRendered } from "./store/root-slice";
import { setIsAuthenticated, setProfile } from "./store/account-slice";
import { CirclesWithBar } from "react-loader-spinner";
import { useLazyAxios } from "./hooks/useAxios";
import { ReferralPage } from "./pages/referrals";
import { ResetPassword } from "./pages/ResetPassword";

const App = () => {
  const {
    account: { isAuthenticated },
    root: { isRendered },
  } = useSelector((state: AppStore) => state);
  useNProgress();
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster gutter={1} position="top-center" />
      {!isRendered ? (
        <SplashScreen />
      ) : (
        <Routes>
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<CustomersPage />} />
            <Route path="customers" element={<CustomersPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="referrals" element={<ReferralPage />} />
          </Route>

          <Route path="/:lang/reset-password" element={<ResetPassword />} />
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
          </Route>

          <Route path="*" element={<Page404 />} />
        </Routes>
      )}
    </ThemeProvider>
  );
};

export default App;

const SplashScreen = () => {
  const dispatch = useDispatch();
  const { execute } = useLazyAxios("api/Account/MyProfile");

  useEffect(() => {
    (async () => {
      const response = await execute();

      if (response?.response.data) dispatch(setProfile(response?.response.data));

      dispatch(setIsRendered(true));
    })();
  }, []);

  return (
    <>
      <div className="splash-screen">
        <img
          src="/logo.png"
          alt="Energy Sleep Logo"
          style={{
            width: "10%",
            height: "auto",
          }}
        />
        <div className="loader-container">
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            outerCircleColor="#4fa94d"
            innerCircleColor="#4fa94d"
            barColor="#4fa94d"
            ariaLabel="circles-with-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        <p className="loading-text">Bitte warten...</p>
      </div>
    </>
  );
};
