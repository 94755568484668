import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import { Box, Button, Container, SortDirection, Stack, SvgIcon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Title } from "src/components/title";
import { useSelection } from "src/hooks/use-selection";
import { useLazyAxios } from "src/hooks/useAxios";
import { SearchBox } from "src/components/search-box";
import { CustomersTable } from "src/pages/customers/customers-table";
import { ReferralsTable } from "./referrals-table";
import { usePaginator } from "src/hooks/use-paginator";

export const ReferralPage = () => {
  const {
    page,
    query,
    rowsPerPage,
    setDirection,
    setPage,
    setQuery,
    setRowsPerPage,
    setSortColumn,
    sortColumn,
    sortDirection,
  } = usePaginator();

  const { isLoading, response, execute } = useLazyAxios("api/Referals/AllReferrals");
  const loadData = async () => {
    await execute(undefined, {
      query: query,
      sortColumn: sortColumn as any,
      pageSize: rowsPerPage,
      pageIndex: page,
      sortDirection: sortDirection,
    });
  };
  const totalRecords = response?.count || 0;
  const data = response?.items || [];

  useEffect(() => {
    loadData();
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  return (
    <>
      <Title title="Empfehlungen" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography variant="h4">Empfehlungen</Typography>
            </Stack>

            <SearchBox
              label="Suche"
              onSubmit={() => {
                setPage(0);
                loadData();
              }}
              setText={setQuery}
              text={query}
            />
            <ReferralsTable
              count={totalRecords}
              items={data}
              onPageChange={(e, v) => {
                setPage(v);
              }}
              onRowsPerPageChange={(e) => {
                setPage(0);
                setRowsPerPage(e.target.value);
              }}
              page={page}
              rowsPerPage={rowsPerPage}
              setDirection={setDirection}
              setSortColumn={setSortColumn}
              sortDirection={sortDirection}
              sortColumn={sortColumn}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};
