import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { Title } from "src/components/title";
import { DashboardLayout } from "src/layouts/dashboard/layout";
import { AccountProfile } from "src/pages/profile/account-profile";
import { AccountProfileDetails } from "src/pages/profile/account-profile-details";

export const ProfilePage = () => (
  <>
    <Title title="Profil" />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Konto</Typography>
          </div>
          <div>
            <Grid container spacing={3}>
              {/* <Grid
                xs={12}
                md={6}
                lg={4}
              >
                <AccountProfile />
              </Grid> */}
              <Grid xs={12} md={12} lg={12}>
                <AccountProfileDetails />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  </>
);
